/* eslint-disable no-underscore-dangle */
import router from '@/router'
import axios from 'axios'
import authService from './auth.service'

// const authService = require('./auth.service')
/** Default config for axios instance */
const API_ENDPOINT = 'https://backend.bosfor-estate.ru/'
const config = {
  baseURL: `${API_ENDPOINT}`,
  withCredentials: true,
}

/** Creating the instance for axios */
const httpClient = axios.create(config)

/** Auth token interceptors */
const authInterceptor = queryConfig => {
  // eslint-disable-next-line no-param-reassign
  queryConfig.headers.Authorization = `Bearer ${authService.getToken()}`
  return queryConfig
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor)

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => response,
  error => {
    console.log('interceptor: error: ', error.response)
    if (error.response.status === 401) {
      // localStorage.removeItem('ACCESS_TOKEN')
      console.log('catched 401 error - get outta here')
      router.push({ name: 'login' })
    }

    /** TODO: Do something with response error */
    return Promise.reject(error)
  },
)

// Add a response interceptor
httpClient.interceptors.response.use(
  response =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  async error => {
    console.log('got error :(', error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config
    if (
      error.response.status === 401 &&
      (originalRequest.url.includes('site/refresh-token') || originalRequest.url.includes('site/logout'))
    ) {
      console.log('well, mate, looks like u got 401 - good bye!')
      router.push('/login')
      return Promise.reject(error)
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      await authService.refreshToken().then(({ success }) => {
        if (success) {
          return httpClient(originalRequest)
        }
        console.log('u got 401, and retry didnt help... sorry')
        router.push('/login')
        return true
      })

      // await store.dispatch("refreshToken");
      return httpClient(originalRequest)
    }

    return Promise.reject(error)
  },
)

httpClient.qs = (val, simpleArrayOfIds = false) => {
  if (simpleArrayOfIds) {
    const params = new URLSearchParams()
    val.forEach((value, key) => {
      params.append(`id[${key}]`, value)
    })

    return params.toString()
  }

  return new URLSearchParams(val).toString()
}

export default httpClient
