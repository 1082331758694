import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.config.productionTip = false
import VueMask from 'v-mask'
Vue.use(PerfectScrollbar)
Vue.use(VueMask);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

