import Vue from 'vue'
import Vuex from 'vuex'
import httpClient from '@/services/http.service'
import retention from "@/store/retention";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dashboard: {},
    channels: [],
    favChannels: [], //избранные рекламные каналы
    favPosts: [], //избранные рекламные посты
    objects: [],
    promoCodes: [],
    adChannels:[],
    adPosts:[],
    adPublications:[],
    adInvites: [],
    adSlots: [],
    adPayments: [],
    adPurchases: [],
    bots: [],
  },
  getters: {
    adPublications(state) {
      return state.adPublications.filter(x=>x.is_active===1).map(x => {
        if (!x.stats) x.stats = {}
        if (!x.stats.channel) x.stats.channel = {
          via_bot: 0,
          subs_24h: 0,
          subs_48h: 0,
          subs_1w: 0,
          subs_total: 0,
          subs_alive: 0,
          via_channel: 0,
        }
        x.adPost = state.adPosts.find(y => y.id === x.ad_post_id)
        x.adChannel = state.adChannels.find(y => y.id === x.ad_channel_id)
        x.channel = state.channels.find(y => y.id === x.channel_id)
        x.subscribersAlivePercent = x.subscribers > 0 ? (x.activeSubscribers / x.subscribers * 100).toFixed(0).toLocaleString() : 0
        x.subscriberPrice = x.activeSubscribers ? x.price/x.activeSubscribers : 0
        x.subscriberFirstDay = x.subscribersFirstDay ? x.price/x.subscribersFirstDay : 0
        x.subscriberTwoDays = x.subscribersTwoDays ? x.price/x.subscribersTwoDays : 0
        x.subscriberTwoDays < x.subscriberFirstDay ? x.subscriberTwoDays = x.subscriberFirstDay : true //рустик: и второй моментик, нужно настроить что если число подп при 48 часах будет меньше чем при 24, то в 48 часах оставить значение из 24 часов
        x.subscriberWeek = x.subscribersWeek? x.price/x.subscribersWeek : 0
        x.cpm = x.views ? x.price / x.views * 1000 : 0
        x.joinRatio = x.subscribers && x.views ? (x.activeSubscribers / x.views * 100).toFixed(2) : 1

        // x.subscribers = x.subscribers ?? 1
        // x.activeSubscribers === 0 ? x.activeSubscribers = 1 : true
        // x.activeSubscribers = x.activeSubscribers ?? 1

        return x
      })
    },
    adPosts(state) {
      return state.adPosts.filter(x=>x.is_active)
    },
    adChannels(state) {
      return state.adChannels.filter(x=>x.is_active)
    },
    adSlots(state) {
      return state.adSlots.map(x=>{
        const payment = state.adPayments.find(p => p.id === x.ad_payment_id) //если добавить в геттеры, то не работает (потому что в геттере adPayments ищется adSlots, а тут наоборот)
        x.channel = state.channels.find(y=>y.id===x.channel_id)
        x.adChannel = state.adChannels.find(y=>y.id===x.ad_channel_id)
        x.adPost = state.adPosts.find(y=>y.id===x.ad_post_id)
        x.adPublication = state.adPublications.find(y=>y.id===x.ad_publication_id)
        return {
          ...x,
          adPayment: payment || null,
        }
      })
    },
    adPayments(state){
      return state.adPayments.map(x=>{
        // x.adSlot = state.adSlots.find(y=>y.id===x.ad_slot_id)
        // x.adPublication = state.adPublications.find(y=>y.id===x.ad_publication_id)
        x.adChannel = state.adChannels.find(y=>y.id===x.ad_channel_id)
        x.channel = state.channels.find(y=>y.id===x.channel_id)
        x.slots = state.adSlots.filter(y=>y.ad_payment_id===x.id)
        x.slotsFree = x.slots.length ? x.slots.filter(y=>!y.is_posted && y.is_paid) : []
        x.slotsPosted = x.slots.length ? x.slots.filter(y=>y.is_posted) : []
        return x
      })
    },
    adSlotStatuses(state){
      return state.adSlots.map(x=>x.status).filter((value, index, self) => self.indexOf(value) === index && value)
    },
    adPublicationStatuses(state){
      return state.adPublications.map(x=>x.status).filter((value, index, self) => self.indexOf(value) === index && value)
    },
    paymentMethods(state) {
      //get all unique and not null payment_method from state.adPublications
      return state.adPublications.map(x => x.payment_method).filter((value, index, self) => self.indexOf(value) === index && value)
    },
    adChannelCategories(state) {
      return state.adChannels.filter(x=>x.category).map(x => x.category).filter((value, index, self) => self.indexOf(value) === index && value)
    },
    adPostCategories(state){
      return state.adPosts.filter(x=>x.category).map(x => x.category).filter((value, index, self) => self.indexOf(value) === index && value)
    },
    bots(state){
      return state.bots
    },
    botPublications: (state) => (date = null) => {
      //if date is null, set date to today in y-m-d
      if (!date) date = new Date().toISOString().slice(0, 10)
      // const pubs = JSON.parse(JSON.stringify(state.adPublications.filter(x => x.bot_id)))
      const pubs = JSON.parse(JSON.stringify(state.adPublications))
      return pubs.map(x => {
        x.bot = state.bots.find(y => y.id === x.bot_id)
        x.adChannel = state.adChannels.find(y => y.id === x.ad_channel_id)
        x.adPost = state.adPosts.find(y => y.id === x.ad_post_id)
        x.adSlot = state.adSlots.find(y => y.id === x.ad_slot_id)
        x.adPayment = state.adPayments.find(y => y.id === x.ad_payment_id)
        if (x.stats && x.stats.bot && x.stats.bot[date]) {
          x.stats.bot = x.stats.bot[date]
        }else{
          if (!x.stats){
            x.stats = {bot: null}
          }else{
            x.stats.bot = null
          }
        }
        return x
      })
    }

  },
  mutations: {
    setDashboard(state, payload) {
      state.dashboard = payload
    },
    setObjects(state, payload) {
      state.objects = payload
    },
    addObject(state, payload) {
      state.objects.push(payload)
      localStorage.setItem('objects', JSON.stringify(state.objects))
    },
    setPromoCodes(state, payload){
      state.promoCodes = payload
    },
    setChannels(state, payload) {
      state.channels = payload
    },
    setAdChannels(state, payload){
      state.adChannels = payload
    },
    addAdChannel(state, payload){
      state.adChannels.push(payload)
    },
    updateAdChannel(state, payload){
      if (state.adChannels.some(x=>x.id===payload.id)){
        Object.assign(state.adChannels.find(x => x.id === payload.id), payload)
      }else{
        state.adChannels.push(payload)
      }
    },
    setAdPosts(state, payload){
      state.adPosts = payload
    },
    addAdPost(state, payload){
      state.adPosts.push(payload)
    },
    updateAdPost(state, payload){
      if (state.adPosts.some(x=>x.id===payload.id)){
        Object.assign(state.adPosts.findIndex(x=>x.id===payload.id), payload)
      }else{
        state.adPosts.push(payload)
      }
    },
    setAdPublications(state, payload){
      state.adPublications = payload
    },
    addAdPublication(state, payload){
      state.adPublications.push(payload)
    },
    updateAdPublication(state, payload){
      if (state.adPublications.some(x=>x.id===payload.id)){
        Object.assign(state.adPublications.findIndex(x=>x.id===payload.id), payload)
        console.log(JSON.parse(JSON.stringify(state.adPublications.find(x=>x.id===payload.id))))
      }else{
        state.adPublications.push(payload)
      }
    },
    deleteAdPublication(state, payload){
      if (state.adPublications.some(x=>x.id===payload)){
        state.adPublications = state.adPublications.filter(x=>x.id!==payload)
      }
    },
    setAdInvites(state, payload){
      state.adInvites = payload
    },
    addAdInvite(state, payload){
      state.adInvites.push(payload)
    },
    updateAdInvite(state, payload){
      if (state.adInvites.some(x=>x.id===payload.id)){
        Object.assign(state.adInvites.findIndex(x=>x===payload.id), payload)
      }
    },
    setFavChannels(state, payload){
      state.favChannels = payload
    },
    toggleFavChannel(state, payload){
      if (!state.favChannels.some(x=>x===payload)){
        state.favChannels.push(payload)
      }else{
        state.favChannels = state.favChannels.filter(x=>x!==payload)
      }
      localStorage.setItem('favChannels', JSON.stringify(state.favChannels))
    },
    setFavPosts(state, payload){
      state.favPosts = payload
    },
    toggleFavPost(state, payload){
      if(!state.favPosts.some(x=>x===payload)){
        state.favPosts.push(payload)
      }else{
        state.favPosts = state.favPosts.filter(x=>x!==payload)
      }
      localStorage.setItem('favPosts', JSON.stringify(state.favPosts))
    },
    setAdSlots(state, payload){
      state.adSlots = payload
    },
    updateAdSlot(state, payload){
      if (state.adSlots.some(x=>x.id===payload.id)){
        Object.assign(state.adSlots.findIndex(x=>x.id===payload.id), payload)
      }else{
        state.adSlots.push(payload)
      }
    },
    setAdPayments(state, payload){
      state.adPayments = payload
    },
    updateAdPayment(state, payload){
      if (state.adPayments.some(x=>x.id===payload.id)){
        Object.assign(state.adPayments.findIndex(x=>x.id===payload.id), payload)
      }else{
        state.adPayments.push(payload)
      }
    },
    setBots(state, payload){
      state.bots = payload
    },
    updateBot(state, payload){
      if (state.bots.some(x=>x.id===payload.id)){
        Object.assign(state.bots.findIndex(x=>x.id===payload.id), payload)
      }else{
        state.bots.push(payload)
      }
    }
  },
  actions: {
    load(ctx, {url, mutation}){
      httpClient.get(url).then(({data})=>{
        ctx.commit(mutation, data)
      })
    },
    loadLocalStorage(ctx, {lsKey, mutation}){
      localStorage.getItem(lsKey) == null ? ctx.commit(mutation, []) : ctx.commit(mutation, JSON.parse(localStorage.getItem(lsKey)))
    },
    loadAndSave(context, { url, mutation, lsKey, force = false }) {
      console.log('load and save', url, mutation, lsKey, force)
      if (force) {
        httpClient.get(url).then(({ data }) => {
          context.commit(mutation, data)
          localStorage.setItem(lsKey, JSON.stringify(data))
        })
      } else if (localStorage.getItem(lsKey) === null) {
        context.dispatch('loadAndSave', {
          url,
          mutation,
          lsKey,
          force: true,
        })
      } else {
        const items = JSON.parse(localStorage.getItem(lsKey))
        if (items.length === 0) {
          context.dispatch('loadAndSave', {
            url,
            mutation,
            lsKey,
            force: true,
          })
        } else {
          context.commit(mutation, items)
        }
      }
    },
    add(ctx, {mutation, payload}){
      console.log('add action...', mutation, payload)
      ctx.commit(mutation, payload)
    },
    addObject(ctx, data) {
      ctx.commit('addObject', data)
    },
  },
  modules: {
    retention: retention
  },
})
