import jwt_decode from 'jwt-decode'

import router from '../router'
import httpClient from './http.service'

/**
 * Created by TheCodeholic on 3/7/2020.
 */
// const httpClient = require('./http.service')

console.log('auth service running...')
const authService = {
  currentUser: null,
  isLoggedIn() {
    return !!localStorage.getItem('ACCESS_TOKEN')
  },
  getToken() {
    console.log('they ask to get them token...')
    return localStorage.getItem('ACCESS_TOKEN')
  },
  async login(formData) {
    console.log('login...')
    try {
      // eslint-disable-next-line no-unused-vars
      const { status, data } = await httpClient.post('/site/login', formData)
      if (data.success) {
        const jwt = jwt_decode(data.token)
        console.log('jwt: ', jwt)
        // store.dispatch('setUserId', jwt.user_id)
        localStorage.setItem('ACCESS_TOKEN', data.token)
        // localStorage.setItem('userData', JSON.stringify(data.user))
        return {
          success: true,
        }
      }

      return {
        success: false,
        errors: data.errorDesc,
      }
    } catch (e) {
      console.log(e)

      return {
        success: false,
        errors: e,
      }
    }
  },
  async register(formData) {
    try {
      const { status, data } = await httpClient.post('user/register', formData)
      if (status === 200) {
        localStorage.setItem('ACCESS_TOKEN', data.access_token)
      }

      return {
        success: true,
      }
    } catch (e) {
      console.log(e.response)

      return {
        success: false,
        errors: e,
      }
    }
  },
  logout() {
    httpClient.post('site/logout')
    localStorage.removeItem('ACCESS_TOKEN')
    router.push('/login')
  },
  async getUser() {
    try {
      if (!this.currentUser) {
        const { status, data } = await httpClient.get('/user/data')
        if (status === 200) {
          this.currentUser = data
        }
      }
    } catch (e) {
      return null
    }

    return this.currentUser
  },
  async refreshToken() {
    try {
      // eslint-disable-next-line no-unused-vars
      const { status, data } = await httpClient.post('/site/refresh-token')
      if (data.success) {
        localStorage.setItem('ACCESS_TOKEN', data.token)

        return {
          success: true,
        }
      }
      localStorage.removeItem('ACCESS_TOKEN')

      return {
        success: false,
        errors: data.errorDesc,
      }
    } catch (e) {
      localStorage.removeItem('ACCESS_TOKEN')
      console.log(e.response)

      return {
        success: false,
        errors: e,
      }
    }
  },
}

export default authService
