import httpClient from "@/services/http.service";

export default {
  namespaced: true,
  state: () => ({
    stats: [],
  }),
  getters: {
    getStats: (state) => state.stats,
  },
  mutations: {
    setStats(state, stats){
      state.stats = stats
    }
  },
  actions: {
    loadStats(ctx){
      httpClient.get('/retention/stats').then(({data})=>{
        ctx.commit('setStats', data)
      })
    }

  },

}
