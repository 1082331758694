<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-card-text>
            <v-sheet height="64">
              <v-toolbar
                flat
              >
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Сегодня
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="prev"
                >
                  <v-icon small>
                    {{ mdiChevronLeft }}
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                >
                  <v-icon small>
                    {{mdiChevronRight}}
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                  bottom
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                :events="slots"
                event-start="planned_at"
                :type="type"
                :locale-first-day-of-year="0"
                color="primary"
              ></v-calendar>
              <!--        <v-menu-->
              <!--          v-model="selectedOpen"-->
              <!--          :close-on-content-click="false"-->
              <!--          :activator="selectedElement"-->
              <!--          offset-x-->
              <!--        >-->
              <!--          <v-card-->
              <!--            color="grey lighten-4"-->
              <!--            min-width="350px"-->
              <!--            flat-->
              <!--          >-->
              <!--            <v-toolbar-->
              <!--              :color="selectedEvent.color"-->
              <!--              dark-->
              <!--            >-->
              <!--              <v-btn icon>-->
              <!--                <v-icon>mdi-pencil</v-icon>-->
              <!--              </v-btn>-->
              <!--              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>-->
              <!--              <v-spacer></v-spacer>-->
              <!--              <v-btn icon>-->
              <!--                <v-icon>mdi-heart</v-icon>-->
              <!--              </v-btn>-->
              <!--              <v-btn icon>-->
              <!--                <v-icon>mdi-dots-vertical</v-icon>-->
              <!--              </v-btn>-->
              <!--            </v-toolbar>-->
              <!--            <v-card-text>-->
              <!--              <span v-html="selectedEvent.details"></span>-->
              <!--            </v-card-text>-->
              <!--            <v-card-actions>-->
              <!--              <v-btn-->
              <!--                text-->
              <!--                color="secondary"-->
              <!--                @click="selectedOpen = false"-->
              <!--              >-->
              <!--                Cancel-->
              <!--              </v-btn>-->
              <!--            </v-card-actions>-->
              <!--          </v-card>-->
              <!--        </v-menu>-->
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-text></v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mdiChevronLeft, mdiChevronRight} from '@mdi/js'

import {computed, ref} from "@vue/composition-api";
import store from "@/store";

export default {
  name: "Calendar",
  mounted () {
    this.$refs.calendar.checkChange()
  },
  setup(){
    const calendar = ref()
    const focus = ref('')
    const type = ref('month')
    const typeToLabel = ref({
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      })

    const allSlots = computed(()=>store.getters.adSlots)
    const slots = computed(()=>allSlots.value.map(slot=>({
      name: slot.adChannel.name,
      planned_at: slot.planned_at,
      color: slot.color,
    })).filter(slot=>slot.planned_at))

    const setToday = () => {
      focus.value = ''
    }

    const prev = () => {
      calendar.value.prev()
    }

    const next = () => {
      calendar.value.next()
    }




    return{
      calendar,
      focus, type,
      typeToLabel,
      setToday,
      prev,
      next,
      slots,
      mdiChevronRight, mdiChevronLeft,
    }
  }
}
</script>

<style scoped>

</style>
