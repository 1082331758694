import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from './default-preset/preset'
import ru from 'vuetify/es5/locale/ru'
Vue.use(Vuetify)

export default new Vuetify({
  preset,
  lang: {
    locales: { ru },
    current: 'ru',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
