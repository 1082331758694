<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>

import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import FullWidthContent from '@/layouts/FullWidthContent.vue'
import store from './store'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    FullWidthContent,
  },
  setup() {
    store.dispatch('load', {url: 'dashboard', mutation: 'setDashboard'})
    store.dispatch('load', {url: 'channels', mutation: 'setChannels'})
    store.dispatch('loadAndSave', {url: 'objects', mutation: 'setObjects', lsKey: 'objects', force: true})
    store.dispatch('load', {url: 'promo-codes?expand=tguser', mutation: 'setPromoCodes'})
    store.dispatch('load', {url: 'ad/posts?expand=photos,publications&sort=-id', mutation: 'setAdPosts'})
    store.dispatch('load', {url: 'ad/channels?expand=publications,subscribers', mutation: 'setAdChannels'})
    store.dispatch('load', {url: 'ad/invites', mutation: 'setAdInvites'})
    store.dispatch('load', {url: 'ad/publications', mutation: 'setAdPublications'})
    store.dispatch('loadAndSave', {url: 'ad/slots', mutation: 'setAdSlots', lsKey: 'adSlots', force: true})
    store.dispatch('loadAndSave', {url: 'ad/payments', mutation: 'setAdPayments', lsKey: 'adPayments', force: true})
    store.dispatch('loadAndSave', {url: 'bots', mutation: 'setBots', lsKey: 'bots', force: true})
    store.dispatch('loadLocalStorage', {mutation: 'setFavChannels', lsKey: 'favChannels'})
    store.dispatch('loadLocalStorage', {mutation: 'setFavPosts', lsKey: 'favPosts'})
    // store.dispatch('load', {url: 'promo-codes?expand=tguser', mutation: 'setPromoCodes'})
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'full-width-content') return 'full-width-content'
      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
